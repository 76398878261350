<app-common-layout>
  <div class="content-container">
    <div class="content-wrapper" [class.tryForce]="orderState.checkoutType === 'tryForce'">
      <ng-template
        *ngIf="orderState.checkoutType === 'tryForce'; then tryForce_topSection; else getForce_topSection"
      ></ng-template>
      <ng-template #getForce_topSection>
        <h1 *ngIf="!isVendorSpecific; else vendorSpecificTpl" class="title">
          Start your {{ orderState.trialDaysCount() }} day free trial.
        </h1>
        <ng-template #vendorSpecificTpl>
          <h1 class="title smaller-fs">Sign up to access your vehicle data</h1>
        </ng-template>
        <div class="vwo-sub-title">
          <b>We'll ship you GPS devices for each one of your vehicles, so you can try Force by Mojio risk-free.</b>
        </div>
        <app-faq-tooltip></app-faq-tooltip>
      </ng-template>
      <ng-template #tryForce_topSection>
        <div class="tryForce-top-container">
          <div class="tryForce-content">
            <img class="device-img" src="/assets/images/phone-device.png" />
            <div class="tryForce-desc">
              <div class="tryForce-desc-title">
                Running a business is hard. Force by Mojio is easy. Get a free GPS tracker, plug it in, and go. If it’s
                not for you, send it back, no charge.
              </div>
              <div>
                <div class="tryForce-desc-item">
                  <cds-icon shape="car" class="icon"></cds-icon>
                  <h3>${{ orderState.oneVehicleTrackingPricePerMonth() }} per vehicle/month</h3>
                </div>
                <div class="tryForce-desc-item">
                  <cds-icon shape="thumbs-up" class="icon"></cds-icon>
                  <h3>No contract, no extra fees</h3>
                </div>
                <div class="tryForce-desc-item">
                  <cds-icon shape="envelope" class="icon"></cds-icon>
                  <h3>Free 2-day shipping</h3>
                </div>
              </div>
            </div>
          </div>
          <h1 class="tryForce-title">Start your {{ orderState.trialDaysCount() }} day free trial.</h1>
        </div>
      </ng-template>

      <div *ngIf="orderState.checkoutType === 'getForce'" class="need-help-sm">
        Need help? <a class="phone" href="tel:806-855-8255">806-855-8255</a>
      </div>
      <div class="content">
        <div class="left-side">
          <h2 class="content-title" [class.tryFoce]="orderState.checkoutType === 'tryForce'">
            <ng-content select="page-title"></ng-content>
          </h2>
          <ng-content></ng-content>
        </div>
        <div class="right-side">
          <div class="order-summary-container">
            <div class="dots-background"></div>
            <div class="gray-background"></div>
            <!-- START OF VWO BLOCK -->
            <div *ngIf="orderState.checkoutType === 'getForce'" class="vwo-order-summary-wrapper">
              <div class="vwo-order-summary">
                <h2 class="vwo-order-summary-title">No Costs - First {{ orderState.trialDaysCount() }} Days</h2>
                <div class="vwo-order-summary-sub-title">
                  Don't pay a dime during the {{ orderState.trialDaysCount() }} day free trial. If you are not satisfied
                  with Force by Mojio, you can return the GPS tracking devices and you will not be charged.
                </div>
              </div>
            </div>
            <!-- END OF VWO BLOCK -->
            <div class="order-summary-wrapper">
              <app-widget-order-summary [isVendorSpecific]="isVendorSpecific">
                <ng-content select="order-widget-content"></ng-content>
              </app-widget-order-summary>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-common-layout>
