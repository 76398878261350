import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Observable, of, Subject, Subscription, timer } from 'rxjs';
import { catchError, distinctUntilChanged, finalize, map, pairwise, share, take, takeUntil } from 'rxjs/operators';
import { AsyncValidatorsService } from 'src/app/core/forms/async-validators/async-validators.service';
import { EmailValidator } from 'src/app/core/forms/validators/email.validator';
import { RegistrationLoader } from 'src/app/core/loaders/registration.loader';
import { IAddressDetails } from 'src/app/core/models/i-order-state.model';
import { OrderStateService } from 'src/app/core/service/order-state.service';
import { RecurlyFormComponent } from 'src/app/shared/components/recurly/components/recurly-form/recurly-form.component';
import { RecurlyValidatorsService } from 'src/app/shared/components/recurly/services/recurly-validators.service';
import { IRecurlyError } from 'src/app/shared/components/recurly/types/recurly-error';
import { AddressFormComponent } from '../../components/address-form/address-form.component';
import { VendorInfoLoader } from '../../../../core/loaders/vendor-info.loader';
import { DOCUMENT } from '@angular/common';
import { TCHECKOUT_TYPE } from 'src/app/core/service/checkout-type.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { ICaptchaInformationReq, IRegistrationPostResDto } from 'src/app/core/endpoints/registrations.endpoints';

@Component({
  templateUrl: './order-single-step.component.html',
  styleUrls: ['./order-single-step.component.scss'],
  providers: [RecurlyValidatorsService],
})
export class OrderSingleStepComponent implements OnInit, OnDestroy, AfterViewChecked {
  private static readonly CAPTCHA_ACTION_NEW_ORDER = 'NewForceOrder';

  public shippingFormPlaceholders = {
    companyName: 'Shipping Company',
    firstName: 'Shipping First name',
    lastName: 'Shipping Last name',
    address1: 'Shipping Street address',
    address2: 'Shipping Street address 2 (Optional)',
    city: 'Shipping City',
    postalCode: 'Shipping Zip',
  };
  public billingFormPlaceholders = {
    companyName: 'Billing Company',
    firstName: 'Billing First name',
    lastName: 'Billing Last name',
    address1: 'Billing Street address',
    address2: 'Billing Street address 2 (Optional)',
    city: 'Billing City',
    postalCode: 'Billing Zip',
  };

  public showSecondAddressForm = false;
  public orderDetailsForm!: FormGroup;
  public cardDetailsForm = new FormGroup({
    cardDetails: new FormControl(''),
  });

  public counter$: Observable<number> = of(0);
  public reCaptchaFailed = false;
  public retryCount = 10;

  public isLoading = false;
  public isVendorSpecific = false;
  public emailTabSubj$: Subject<boolean> = new Subject<boolean>();
  public phoneTabSubj$: Subject<boolean> = new Subject<boolean>();

  private billingAddressFormSubs!: Subscription;
  private shippingAddressFormSubs!: Subscription;
  private billingAddressFormInstance!: AddressFormComponent;
  public shippingAddressFormInstance!: AddressFormComponent;

  @ViewChild('emailInput', { static: false })
  private emailInputElRef!: ElementRef<HTMLInputElement>;

  @ViewChild('phoneInput', { static: false })
  private phoneInputElRef!: ElementRef<HTMLInputElement>;

  @ViewChild('billingAddressForm', { static: false })
  public set billingAddressForm(addressForm: AddressFormComponent) {
    this.billingAddressFormInstance = addressForm;

    if (this.billingAddressFormSubs) {
      this.billingAddressFormSubs.unsubscribe();
    }

    if (addressForm) {
      this.billingAddressFormSubs = addressForm.valueChanges().subscribe((newVal) => {
        this.orderState.setBillingAddress(newVal, addressForm.isValid());
      });
    }
    const state = this.orderState.stateValue();
    if (addressForm && state.isValidBillingAddress && state.billingAddress) {
      addressForm.setValue(state.billingAddress);
    }
  }

  public get billingAddressForm(): AddressFormComponent {
    return this.billingAddressFormInstance;
  }

  @ViewChild('shippingAddressForm', { static: false })
  set shippingAddressForm(addressForm: AddressFormComponent) {
    this.shippingAddressFormInstance = addressForm;
    const { shippingAddress, isValidShippingDetails } = this.orderState.stateValue();

    if (this.shippingAddressFormSubs) {
      this.shippingAddressFormSubs.unsubscribe();
    }

    if (addressForm) {
      this.shippingAddressFormSubs = addressForm.valueChanges().subscribe((newVal) => {
        this.orderState.setShippingAddress(newVal, addressForm.isValid());
      });
    }
    if (addressForm && isValidShippingDetails && shippingAddress) {
      addressForm.setValue(shippingAddress);
    }
  }

  @ViewChild(RecurlyFormComponent, { static: false })
  public recurlyFormControl!: RecurlyFormComponent;

  private destroy$ = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private asyncValidators: AsyncValidatorsService,
    private orderState: OrderStateService,
    private registrationLoader: RegistrationLoader,
    private vendorInfoLoader: VendorInfoLoader,
    private recurlyValidators: RecurlyValidatorsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
    private recaptchaV3Service: ReCaptchaV3Service,
  ) {}

  public ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }

  public ngOnInit(): void {
    if (this.orderState.isOrderCompleted()) {
      this.orderState.resetState();
    }

    this.orderDetailsForm = new FormGroup({
      email: new FormControl('', {
        validators: [Validators.required, EmailValidator],
        asyncValidators: this.asyncValidators.emailValidator(),
      }),
      phone: new FormControl('', {
        validators: [Validators.required, Validators.minLength(9)],
        asyncValidators: this.asyncValidators.phoneValidator('1'),
      }),
    });

    combineLatest([
      this.emailTabSubj$.pipe(distinctUntilChanged()),
      this.orderDetailsForm.controls.email.statusChanges.pipe(distinctUntilChanged()),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([isPressed, status]) => {
        if (isPressed) {
          // return if control status is still pending
          if (status === 'PENDING') {
            return;
          }
          // move focus otherwise
          if (status === 'INVALID' || status === 'VALID') {
            this.emailTabSubj$.next(false);
            this.phoneInputElRef.nativeElement.focus();
          }
        }
      });

    combineLatest([
      this.phoneTabSubj$.pipe(distinctUntilChanged()),
      this.orderDetailsForm.controls.phone.statusChanges.pipe(pairwise()),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([isPressed, [prev, curr]]) => {
        if (isPressed) {
          // return if control status is still pending
          if (curr === 'PENDING') {
            return;
          }
          // show email control errors if present
          if (!this.orderDetailsForm.controls.email.valid) {
            this.orderDetailsForm.controls.email.markAsTouched();
          }
          // control status is invalid
          if (prev === 'PENDING' && curr === 'INVALID') {
            this.phoneTabSubj$.next(false);
            this.orderDetailsForm.controls.phone.markAsTouched();
            return;
          }
          // control status is valid
          if (curr === 'VALID') {
            this.phoneTabSubj$.next(false);
            if (!this.orderDetailsForm.controls.email.valid) {
              this.emailInputElRef.nativeElement.focus();
              return;
            }
            setTimeout(() => {
              if (!this.billingAddressFormInstance.addressForm.disabled) {
                this.billingAddressFormInstance.companyNameElRef.nativeElement.focus();
              }
            }, 0);
          }
        }
      });

    this.activatedRoute.queryParamMap.subscribe((queryParamMap) => {
      if (queryParamMap.has('promo-code')) {
        this.orderState.applyPromoCode(queryParamMap.get('promo-code') as string);
      }
      if (queryParamMap.has('vfc')) {
        this.isVendorSpecific = true;
        const vendorFulfillmentCode = queryParamMap.get('vfc') as string;
        this.isLoading = true;
        this.vendorInfoLoader
          .loadVendorInfo(vendorFulfillmentCode)
          .pipe(finalize(() => (this.isLoading = false)))
          .subscribe({
            next: (resp) => {
              this.orderState.setDevicesCount(resp.numberOfDevices);
              this.orderState.applyPromoCode(resp.couponCode);
              this.orderState.updateState({ vendorFulfillmentCode });
            },
            error: (e) => {
              this.isVendorSpecific = false;
              console.error('loading vendor specific info failed', e);
            },
          });
      } else {
        this.isVendorSpecific = !!this.getVendorFulfillmentCode();
      }

      if (queryParamMap.has('redirect')) {
        const redirect = queryParamMap.get('redirect') as string;
        this.orderState.updateState({ redirect });
      }
    });

    const { phone, email } = this.orderState.stateValue();
    if (phone && email) {
      this.orderDetailsForm.setValue({
        email,
        phone,
      });
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public getBillingAddress(): IAddressDetails | undefined {
    return this.orderState.getCalculatedBillingAddress();
  }

  public getShippingAddress(): IAddressDetails | undefined {
    return this.orderState.getCalculatedShippingAddress();
  }

  public getUseSameAddress(): boolean {
    return this.orderState.stateValue().sameBillingAndShippingAddress;
  }

  public onUseSameAddressChange(e: any): void {
    this.orderState.updateState({
      sameBillingAndShippingAddress: e.target.checked,
    });
  }

  public getTrialDaysCount(): number {
    return this.orderState.trialDaysCount();
  }

  public getVendorFulfillmentCode(): string | undefined {
    return this.orderState.stateValue().vendorFulfillmentCode;
  }

  public async onPlaceOrderPressed(): Promise<void> {
    try {
      if (this.shippingAddressFormInstance) {
        this.orderState.setShippingAddress(
          this.shippingAddressFormInstance.getValue(),
          this.shippingAddressFormInstance.isValid(),
        );
      }

      const { email, phone } = this.orderDetailsForm.value;
      this.orderState.setCommonDetails({
        email: this.orderDetailsForm.value.email,
        phone: this.orderDetailsForm.value.phone,
        howDidYouFindUs: '',
        howDidYouFindUsComment: '',
      });
      this.isLoading = true;
      const recurlyToken = await this.recurlyFormControl.getToken();

      const promoCode = this.orderState.promoCodeData();
      const couponCode = (promoCode && promoCode.type !== 'invalid' && promoCode.couponCode) || '';

      this.generateReCaptchaToken()
        .pipe(take(1))
        .subscribe((recaptchaToken: string) => {
          if (!recaptchaToken) {
            this.isLoading = false;
            this.startRetryCounter();
            return;
          }
          this.registrationLoader
            .register(
              (recurlyToken as any).id,
              couponCode,
              email || '',
              `1${phone}`,
              '',
              this.orderState.getCalculatedShippingAddress()?.companyName || '',
              this.orderState.billingPeriod() || '',
              this.orderState.devicesCount() || 0,
              this.orderState.getCalculatedShippingAddress() as IAddressDetails,
              this.getBillingAddress() as IAddressDetails,
              this.getVendorFulfillmentCode(),
              {
                token: recaptchaToken,
                action: OrderSingleStepComponent.CAPTCHA_ACTION_NEW_ORDER,
              } as ICaptchaInformationReq,
            )
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe({
              next: (res: IRegistrationPostResDto) => {
                // let user retry if reCAPTCHA fails backend validation
                if (
                  res?.captchaInformation &&
                  res?.captchaInformation?.action === OrderSingleStepComponent.CAPTCHA_ACTION_NEW_ORDER &&
                  !res?.captchaInformation?.isValid
                ) {
                  this.startRetryCounter();
                  return;
                }
                this.orderState.markOrderAsCompleted();
                const redirectUrl = this.orderState.getRedirectAddress();
                const fallbackUrlSuffix = Number(this.orderState.devicesCount()) === 1 ? '/singlevehicle' : '';
                if (!!redirectUrl && !!this.getVendorFulfillmentCode()) {
                  this.redirect(redirectUrl, `/shipping/payment/congratulation${fallbackUrlSuffix}`);
                } else {
                  this.router.navigateByUrl(`/shipping/payment/congratulation${fallbackUrlSuffix}`);
                }
              },
              error: (e) => {
                console.error('register failed', e);
                this.orderState.setRegisterError('unknown');
                this.router.navigateByUrl('/shipping/payment/error');
              },
            });
        });
    } catch (e) {
      this.isLoading = false;
      console.error('Error: getToken', e);
      this.handleRecurlyError(e);
    }
  }

  public redirect(url: string = '', fallbackRoute: string = ''): void {
    if (!url) {
      this.router.navigateByUrl(fallbackRoute);
    } else {
      const urlToRedirect = /^http(s)?:\/\//.test(url) ? url : `https://${url}`;
      try {
        this.document.location.href = urlToRedirect;
      } catch (e) {
        this.router.navigateByUrl(fallbackRoute);
      }
    }
  }

  public isValid(): boolean {
    if (this.checkoutType === 'tryForce') {
      return (
        this.cardDetailsForm.valid &&
        this.shippingAddressFormInstance &&
        this.shippingAddressFormInstance.isValid() &&
        (this.getUseSameAddress() || this.billingAddressFormInstance?.isValid())
      );
    } else {
      return (
        this.cardDetailsForm.valid &&
        this.billingAddressFormInstance &&
        this.billingAddressFormInstance.isValid() &&
        (this.getUseSameAddress() || this.shippingAddressFormInstance?.isValid())
      );
    }
  }

  public onHandleEmailTabPressed(event: Event): void {
    if (event.isTrusted && this.orderDetailsForm.controls.email.status === 'PENDING') {
      event.stopPropagation();
      event.preventDefault();
      this.emailTabSubj$.next(true);
    }
  }

  public onHandlePhoneTabPressed(event: Event): void {
    if (event.isTrusted) {
      event.stopPropagation();
      event.preventDefault();
      switch (this.orderDetailsForm.controls.phone.status) {
        case 'PENDING':
          this.phoneTabSubj$.next(true);
          break;
        case 'INVALID':
          this.orderDetailsForm.controls.phone.markAsTouched();
          break;
        case 'VALID':
          if (!this.orderDetailsForm.controls.email.valid) {
            this.emailInputElRef.nativeElement.focus();
            return;
          }
          setTimeout(() => {
            if (!this.billingAddressFormInstance.addressForm.disabled) {
              this.billingAddressFormInstance.companyNameElRef.nativeElement.focus();
            }
          }, 0);
          break;
        default:
      }
    }
  }

  private generateReCaptchaToken(): Observable<string> {
    return this.recaptchaV3Service.execute(OrderSingleStepComponent.CAPTCHA_ACTION_NEW_ORDER).pipe(
      take(1),
      map((token: string) => token),
      catchError((error) => {
        console.log(`ReCaptcha error generating token:`, error);
        return of('');
      }),
    );
  }

  private startRetryCounter(): void {
    this.reCaptchaFailed = true;
    this.retryCount = 10;
    this.counter$ = timer(0, this.retryCount * 100).pipe(
      take(this.retryCount),
      map(() => {
        if (this.retryCount <= 1) {
          this.reCaptchaFailed = false;
        }
        return --this.retryCount;
      }),
      share(),
    );
  }

  private handleRecurlyError(error: IRecurlyError): void {
    const address = this.getBillingAddress();
    if (
      this.recurlyValidators.handleError(error, {
        first_name: address?.firstName,
        last_name: address?.lastName,
        city: address?.city,
        country: address?.country,
        state: address?.state,
        postal_code: address?.postalCode,
        address1: address?.address1,
        address2: address?.address2,
      })
    ) {
      if (this.billingAddressFormInstance) {
        this.billingAddressFormInstance.revalidate();
      }
      if (this.shippingAddressFormInstance) {
        this.shippingAddressFormInstance.revalidate();
      }
      this.cardDetailsForm.get('cardDetails')?.updateValueAndValidity();
    } else {
      this.orderState.setRegisterError('payment');
      this.router.navigateByUrl('/shipping/payment/error');
    }
  }

  public get checkoutType(): TCHECKOUT_TYPE {
    return this.orderState.checkoutType;
  }
}
