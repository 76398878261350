import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { OrderStateService } from 'src/app/core/service/order-state.service';
import { range } from 'src/app/utils/math';
import { InputPromoComponent } from './input-promo/input-promo.component';

@Component({
  selector: 'app-widget-order-summary',
  templateUrl: './widget-order-summary.component.html',
  styleUrls: ['./widget-order-summary.component.scss'],
})
export class WidgetOrderSummaryComponent implements OnInit, OnDestroy {
  @ViewChild(InputPromoComponent, { static: true })
  public inputPromo!: InputPromoComponent;

  @Input()
  public isVendorSpecific = false;

  public billingPeriod: 'annual' | 'monthly' = 'monthly';

  public devicesCountList = range(1, 50);

  private subscription = new Subscription();

  constructor(public orderState: OrderStateService) {}

  public ngOnInit(): void {
    this.subscription.add(
      this.orderState
        .state()
        .pipe(
          map(({ promoCodeData }) => promoCodeData),
          filter((code) => !!code && code.type !== 'invalid'),
          distinctUntilChanged(),
        )
        .subscribe(() => this.inputPromo.reset()),
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onApplyPromo(promoCode: string): void {
    this.orderState.applyPromoCode(promoCode);
  }

  public onBillingPeriodChanged(isYearlyChecked: boolean): void {
    this.orderState.setBillingPeriod(isYearlyChecked ? 'annual' : 'monthly');
  }

  public onDeviceCountChanged(deviceCount: number): void {
    this.orderState.setDevicesCount(deviceCount);
  }
}
