import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TSubscriptionPlan } from '../types/type';

import { AbstractEndpoints } from './abstract.endpoints';

const FULFILLMENT_URL = '{apiUrl}/fleets/fulfillment';

//

export interface IFulfillmentPlanDto {
  flatShippingRate: number;
  freeShippingQuantityRequirement: number;
  costPerDevice: number;
}

export interface IFulfillmentResponseDto {
  plansByCode: {
    [planCode in TSubscriptionPlan]: IFulfillmentPlanDto;
  };
}

@Injectable({ providedIn: 'root' })
export class FulfillmentEndpoints extends AbstractEndpoints {
  constructor(private httpClient: HttpClient) {
    super();
  }

  public getFulfillment(): Observable<IFulfillmentResponseDto> {
    return this.httpClient.get<IFulfillmentResponseDto>(this.createUrl(FULFILLMENT_URL), {
      params: {
        clientId: this.getClientId(),
      },
    });
  }
}
