<app-final-layout>
  <page-title>Order Confirmed!</page-title>
  <page-subtitle>Welcome to Force, {{ orderState.getCalculatedShippingAddress()?.firstName }}!</page-subtitle>
  <page-sub-content>
    We’ve sent you an email with your order details and will ship your {{ orderState.devicesCount() }}
    {{ orderState.devicesCount() <= 1 ? 'device' : 'devices' }} within 48 hours. You’ll also get an email with links to
    track your shipment and to create your account in Force.
  </page-sub-content>

  <div class="order-table">
    <div class="table-content">
      <div class="row">
        <div class="col-title">
          {{ 'Monthly Subtotal' | annual: 'Yearly Subtotal' }}
        </div>
        <div class="col-price">
          {{ orderState.subTotalPrice() | price }}
        </div>
      </div>
      <div class="row">
        <div class="col-title">Sales Tax</div>
        <div class="col-price">
          {{ orderState.taxesPrice() | price }}
        </div>
      </div>
      <div class="row">
        <div class="col-title">
          {{ 'Monthly Total' | annual: 'Yearly Total' }}
        </div>
        <div class="col-price">
          {{ orderState.subTotalPriceWithTaxes() | price }}
        </div>
      </div>
    </div>
  </div>

  <app-layout-actions>
    <a class="btn-primary" href="https://forcebymojio.com">Close</a>
  </app-layout-actions>
</app-final-layout>

<app-survey-modal></app-survey-modal>
