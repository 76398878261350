import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './routing/app-routing.module';
import { AppComponent } from './app.component';
import { OrderModule } from './view/order/order.module';

import './core/icons/icons';
import { RECURLY_PUBLIC_KEY } from './shared/components/recurly/services/recurly-script-loader';
import { HintModule } from './shared/components/hint/hint.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './core/auth/auth-interceptor';
import { NgxMaskModule } from 'ngx-mask';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { WINDOW_PROVIDERS } from './shared/components/hint/services/window.service';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    OrderModule,
    HintModule.forRoot(),
    NgxMaskModule.forRoot(),
    RecaptchaV3Module,
  ],
  providers: [
    WINDOW_PROVIDERS,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: RECURLY_PUBLIC_KEY, useValue: environment.recurlyPublicKey },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.reCaptchaKey },
    CookieService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
