<div [formGroup]="formGroup">
  <div appFormError="firstName" errorName="required">Please provide first name.</div>
  <div appFormError="firstName" errorName="recurly">
    <b>First Name</b> {{ formGroup.get('firstName')?.errors?.recurly }}
  </div>
  <div appFormError="lastName" errorName="required">Please provide last name.</div>
  <div appFormError="lastName" errorName="recurly">
    <b>Last Name</b> {{ formGroup.get('lastName')?.errors?.recurly }}
  </div>
  <div appFormError="address1" errorName="required">Please enter a street address.</div>
  <div appFormError="address1" errorName="recurly">
    <b>Address 1</b> {{ formGroup.get('address1')?.errors?.recurly }}
  </div>
  <div appFormError="address2" errorName="recurly">
    <b>Address 2</b> {{ formGroup.get('address2')?.errors?.recurly }}
  </div>
  <div appFormError="city" errorName="required">Please enter a city name.</div>
  <div appFormError="city" errorName="recurly"><b>City</b> {{ formGroup.get('city')?.errors?.recurly }}</div>
  <div appFormError="state" errorName="required">Please pick a valid state.</div>
  <div appFormError="state" errorName="recurly"><b>State</b> {{ formGroup.get('state')?.errors?.recurly }}</div>
  <div appFormError="postalCode" errorName="required">Please enter a zipcode.</div>
  <div appFormError="postalCode" errorName="recurly">
    <b>ZIP code</b> {{ formGroup.get('postalCode')?.errors?.recurly }}
  </div>
</div>
