import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonLayoutModule } from 'src/app/shared/layouts/common-layout/common-layout.module';
import { OrderLayoutComponent } from './layouts/order-layout/order-layout.component';
import { StepsBarComponent } from './layouts/order-layout/components/steps-bar/steps-bar.component';
import { WidgetOrderSummaryComponent } from './layouts/order-layout/widgets/widget-order-summary/widget-order-summary.component';
import { InputPromoComponent } from './layouts/order-layout/widgets/widget-order-summary/input-promo/input-promo.component';
import { DropdownModule } from 'src/app/shared/components/dropdown/dropdown.module';
import { RecurlyModule } from 'src/app/shared/components/recurly/recurly.module';
import { AddressFormComponent } from './components/address-form/address-form.component';
import { SelectModule } from 'src/app/shared/forms/select/select.module';
import { CongratulationComponent } from './pages/congratulation/congratulation.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HintModule } from '../../shared/components/hint/hint.module';
import { SelectDropdownModule } from 'src/app/shared/components/select-dropdown/select-dropdown.module';
import { PriceModule } from 'src/app/shared/pipes/price/price.module';
import { FormErrorModule } from 'src/app/shared/forms/form-error/form-error.module';
import { AppInputModule } from 'src/app/shared/forms/input/input.module';
import { NgxMaskModule } from 'ngx-mask';
import { ContentComponent } from './layouts/components/content/content.component';
import { RouterModule } from '@angular/router';
import { AddressFormErrorsComponent } from './components/address-form-errors/address-form-errors.component';
import { AnnualModule } from 'src/app/shared/pipes/annual/annual.module';
import { FinalLayoutComponent } from './layouts/final-layout/final-layout.component';
import { ErrorDetailsComponent } from './pages/error-details/error-details.component';
import { BreakpointModule } from 'src/app/shared/breakpoint/breakpoint.module';
import { OrderSingleStepComponent } from './pages/order-single-step/order-single-step.component';
import { MarkTouchedIfLengthGreaterThanModule } from '../../shared/directives/mark-touched-if-length-greater-than/mark-touched-if-length-greater-than.module';
import { SurveyModalComponent } from './components/survey-modal/survey-modal.component';
import { FaqTooltipComponent } from './components/faq-tooltip/faq-tooltip.component';

@NgModule({
  declarations: [
    OrderLayoutComponent,
    StepsBarComponent,
    WidgetOrderSummaryComponent,
    InputPromoComponent,
    AddressFormComponent,
    CongratulationComponent,
    ContentComponent,
    AddressFormErrorsComponent,
    FinalLayoutComponent,
    ErrorDetailsComponent,
    OrderSingleStepComponent,
    SurveyModalComponent,
    FaqTooltipComponent,
  ],
  imports: [
    CommonModule,
    CommonLayoutModule,
    DropdownModule,
    RecurlyModule,
    SelectModule,
    SelectDropdownModule,
    ReactiveFormsModule,
    HintModule,
    PriceModule,
    FormErrorModule,
    AppInputModule,
    NgxMaskModule,
    RouterModule,
    AnnualModule,
    BreakpointModule,
    FormsModule,
    MarkTouchedIfLengthGreaterThanModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class OrderModule {}
