import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OrderStateService } from 'src/app/core/service/order-state.service';

@Component({
  templateUrl: './congratulation.component.html',
  styleUrls: ['./congratulation.component.scss'],
})
export class CongratulationComponent implements OnInit {
  constructor(
    public orderState: OrderStateService, //
    private router: Router,
  ) {}

  public ngOnInit(): void {
    if (!this.orderState.isOrderCompleted()) {
      this.router.navigateByUrl('/');
    }
  }
}
