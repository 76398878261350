<div class="common-layout">
  <div class="header">
    <a target="_self" href="https://forcebymojio.com/">
      <img class="logo" src="/assets/icons/logo.svg" />
    </a>
    <a *ngIf="orderState.checkoutType === 'getForce'" class="phone" href="tel:806-855-8255">806-855-8255</a>
  </div>

  <div class="content">
    <ng-content></ng-content>
  </div>
</div>
