<form [formGroup]="addressForm">
  <app-input-wrapper>
    <input
      #companyName
      appInputCtrl
      [appMarkTouchedIfLengthGreaterThan]="maxLengthList.companyName"
      formControlName="companyName"
      [maxlength]="maxLengthList.companyName"
      class="app-input"
      type="text"
      name="organization"
      [placeholder]="placeholders.companyName"
    />
    <div class="input-error">
      <div appFormError="companyName" errorName="required">Please enter a company name.</div>
      <div appFormError="companyName" errorName="maxlength">Company name is too long (maximum 100 characters).</div>
    </div>
  </app-input-wrapper>
  <app-input-wrapper>
    <input
      appInputCtrl
      [appMarkTouchedIfLengthGreaterThan]="maxLengthList.firstName"
      formControlName="firstName"
      [maxlength]="maxLengthList.firstName"
      class="app-input"
      type="text"
      name="given-name"
      [placeholder]="placeholders.firstName"
    />
    <div class="input-error">
      <div appFormError="firstName" errorName="required">Please provide first name.</div>
      <div appFormError="firstName" errorName="maxlength">First name is too long (maximum 50 characters).</div>
      <div appFormError="firstName" errorName="recurly">
        First name {{ addressForm.get('firstName')?.errors?.recurly }}
      </div>
    </div>
  </app-input-wrapper>
  <app-input-wrapper>
    <input
      appInputCtrl
      [appMarkTouchedIfLengthGreaterThan]="maxLengthList.lastName"
      formControlName="lastName"
      [maxlength]="maxLengthList.lastName"
      class="app-input"
      type="text"
      name="lname"
      [placeholder]="placeholders.lastName"
    />
    <div class="input-error">
      <div appFormError="lastName" errorName="required">Please provide last name.</div>
      <div appFormError="lastName" errorName="maxlength">Last name is too long (maximum 50 characters).</div>
      <div appFormError="lastName" errorName="recurly">
        Last name {{ addressForm.get('lastName')?.errors?.recurly }}
      </div>
    </div>
  </app-input-wrapper>
  <app-input-wrapper>
    <input
      appInputCtrl
      [appMarkTouchedIfLengthGreaterThan]="maxLengthList.address1"
      formControlName="address1"
      [maxlength]="maxLengthList.address1"
      class="app-input"
      type="text"
      name="street-address"
      [placeholder]="placeholders.address1"
    />
    <div class="input-error">
      <div appFormError="address1" errorName="required">Please enter a street address.</div>
      <div appFormError="address1" errorName="maxlength">Street address is too long (maximum 50 characters).</div>
      <div appFormError="address1" errorName="recurly">Address {{ addressForm.get('address1')?.errors?.recurly }}</div>
    </div>
  </app-input-wrapper>
  <app-input-wrapper>
    <input
      appInputCtrl
      [appMarkTouchedIfLengthGreaterThan]="maxLengthList.address2"
      formControlName="address2"
      [maxlength]="maxLengthList.address2"
      class="app-input"
      type="text"
      autocomplete="nope"
      [placeholder]="placeholders.address2"
    />
    <div class="input-error">
      <div appFormError="address2" errorName="maxlength">
        Street address line 2 is too long (maximum 50 characters).
      </div>
      <div appFormError="address2" errorName="recurly">Address {{ addressForm.get('address2')?.errors?.recurly }}</div>
    </div>
  </app-input-wrapper>

  <div class="form-row">
    <div class="col-50">
      <app-input-wrapper>
        <input
          appInputCtrl
          [appMarkTouchedIfLengthGreaterThan]="maxLengthList.city"
          formControlName="city"
          [maxlength]="maxLengthList.city"
          class="app-input"
          type="text"
          name="home city"
          [placeholder]="placeholders.city"
        />
        <div class="input-error">
          <div appFormError="city" errorName="required">Please enter a city name.</div>
          <div appFormError="city" errorName="maxlength">City name is too long (maximum 50 characters).</div>
          <div appFormError="city" errorName="recurly">City {{ addressForm.get('city')?.errors?.recurly }}</div>
        </div>
      </app-input-wrapper>
    </div>
    <div class="col-50">
      <app-select class="w-100 state-selector" formControlName="state" name="state">
        <option selected value="">State</option>
        <option value="AL">Alabama</option>
        <option value="AK">Alaska</option>
        <option value="AZ">Arizona</option>
        <option value="AR">Arkansas</option>
        <option value="CA">California</option>
        <option value="CO">Colorado</option>
        <option value="CT">Connecticut</option>
        <option value="DE">Delaware</option>
        <option value="DC">District of Columbia</option>
        <option value="FL">Florida</option>
        <option value="GA">Georgia</option>
        <option value="HI">Hawaii</option>
        <option value="ID">Idaho</option>
        <option value="IL">Illinois</option>
        <option value="IN">Indiana</option>
        <option value="IA">Iowa</option>
        <option value="KS">Kansas</option>
        <option value="KY">Kentucky</option>
        <option value="LA">Louisiana</option>
        <option value="ME">Maine</option>
        <option value="MD">Maryland</option>
        <option value="MA">Massachusetts</option>
        <option value="MI">Michigan</option>
        <option value="MN">Minnesota</option>
        <option value="MS">Mississippi</option>
        <option value="MO">Missouri</option>
        <option value="MT">Montana</option>
        <option value="NE">Nebraska</option>
        <option value="NV">Nevada</option>
        <option value="NH">New Hampshire</option>
        <option value="NJ">New Jersey</option>
        <option value="NM">New Mexico</option>
        <option value="NY">New York</option>
        <option value="NC">North Carolina</option>
        <option value="ND">North Dakota</option>
        <option value="OH">Ohio</option>
        <option value="OK">Oklahoma</option>
        <option value="OR">Oregon</option>
        <option value="PA">Pennsylvania</option>
        <option value="RI">Rhode Island</option>
        <option value="SC">South Carolina</option>
        <option value="SD">South Dakota</option>
        <option value="TN">Tennessee</option>
        <option value="TX">Texas</option>
        <option value="UT">Utah</option>
        <option value="VT">Vermont</option>
        <option value="VA">Virginia</option>
        <option value="WA">Washington</option>
        <option value="WV">West Virginia</option>
        <option value="WI">Wisconsin</option>
        <option value="WY">Wyoming</option>
      </app-select>
      <div class="app-select-error" appFormError="state">
        <div appFormError="state" errorName="required">Please pick a valid state.</div>
        <div appFormError="state" errorName="recurly">State {{ addressForm.get('state')?.errors?.recurly }}</div>
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="col-50">
      <app-input-wrapper>
        <input
          appInputCtrl
          [appMarkTouchedIfLengthGreaterThan]="maxLengthList.postalCode"
          formControlName="postalCode"
          [maxlength]="maxLengthList.postalCode"
          class="app-input"
          inputmode="numeric"
          mask="00000"
          [validation]="false"
          type="text"
          name="postal-code"
          [placeholder]="placeholders.postalCode"
        />
        <div class="input-error">
          <div appFormError="postalCode" errorName="required">Please enter a zip code.</div>
          <div appFormError="postalCode" errorName="minlength">Please enter a zip code.</div>
          <div appFormError="postalCode" errorName="maxlength">Zip code is too long (maximum 5 characters).</div>
          <div appFormError="postalCode" errorName="recurly">
            Zip code {{ addressForm.get('postalCode')?.errors?.recurly }}
          </div>
        </div>
      </app-input-wrapper>
    </div>
    <div class="col-50">
      <app-select [readonly]="true" class="w-100" formControlName="country">
        <option selected value="US">US</option>
      </app-select>
    </div>
  </div>
</form>
