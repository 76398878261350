import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-input-promo',
  templateUrl: './input-promo.component.html',
  styleUrls: ['./input-promo.component.scss'],
})
export class InputPromoComponent {
  @Output()
  public apply = new EventEmitter<string>();

  promoForm = new FormGroup({
    promoCode: new FormControl('', Validators.required),
  });

  public onSubmitForm(): void {
    this.apply.emit(this.promoForm.value.promoCode);
  }

  public reset(): void {
    this.promoForm.reset();
  }
}
