import { Injectable, Inject } from '@angular/core';
import { WINDOW } from 'src/app/shared/components/hint/services/window.service';

export type TCHECKOUT_TYPE = 'tryForce' | 'getForce';

@Injectable({
  providedIn: 'root',
})
export class CheckoutTypeService {
  constructor(@Inject(WINDOW) private window: Window) {}

  private getHostname(): string {
    return this.window.location.hostname;
  }

  public getCheckoutType(): TCHECKOUT_TYPE {
    const matched = this.getHostname().match(/^(try|get)(?:-staging)*.forcebymojio.com$/);
    return matched?.[1] === 'try' ? 'tryForce' : ('getForce' as TCHECKOUT_TYPE);
  }
}
