<app-final-layout>
  <page-title>{{ error === 'payment' ? 'Payment Error' : 'Order Failed' }}</page-title>
  <page-subtitle>
    {{ error === 'payment' ? 'There is an error in your card details...' : 'Looks like something went wrong.' }}
  </page-subtitle>
  <page-sub-content>
    {{
      error === 'payment'
        ? 'Please go back and try again.'
        : 'Please go back and check your order details. If it happens again, contact us.'
    }}
  </page-sub-content>
  <div class="actions">
    <button class="btn-primary" (click)="onBackPressed()">Back</button>
  </div>

  <div class="need-help-title">Need Help?</div>
  <div>Please call us at 806-855-8255 or email us at sales@moj.io</div>
</app-final-layout>
