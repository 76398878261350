import { Component, OnInit, ViewChild, ElementRef, forwardRef, Input, OnDestroy } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { CardElement, CardElementOptions, IndividualElement, IndividualElementOptions } from '@recurly/recurly-js';
import { RecurlyElementsService } from '../../services/recurly-elements.service';
import { RecurlyValidatorsService } from '../../services/recurly-validators.service';

export type TRecurlyElementType = 'card' | 'number' | 'month' | 'year' | 'cvv';

const fontFamily = 'Gotham, "Avenir Next", "Helvetica Neue", Arial, sans-serif';
const fontColor = '#292f4c';
const placeholderColor = '#bfbfbf';
const fontSize = '1em';

interface IRecurlyControlStateOpts {
  empty: boolean;
  valid: boolean;
}
export interface IRecurlyControlState {
  cvv?: IRecurlyControlStateOpts;
  number?: IRecurlyControlStateOpts;
  expiry?: IRecurlyControlStateOpts;
}

@Component({
  selector: 'app-recurly-control',
  templateUrl: './recurly-control.component.html',
  styleUrls: ['./recurly-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RecurlyControlComponent),
      multi: true,
    },
  ],
})
export class RecurlyControlComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input()
  public type: TRecurlyElementType = 'card';

  @Input()
  public placeholder = '0000 0000 0000 0000';

  @Input()
  public hasError!: boolean;

  public valid = false;

  public state?: IRecurlyControlState;

  @ViewChild('container', { static: true })
  private containerRef!: ElementRef<HTMLElement>;
  private element!: IndividualElement | CardElement;

  private cvaChangeListener!: (val: string | null) => void;
  private cvaTouchedListener!: () => void;

  constructor(private recurlyElements: RecurlyElementsService, private validatorService: RecurlyValidatorsService) {}

  public async ngOnInit(): Promise<void> {
    this.onBlurHandler = this.onBlurHandler.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);

    this.element = await this.createElement(
      this.type,
      this.type === 'card' ? this.getCardConfig() : this.getElementConfig(),
    );

    this.element.attach(this.containerRef.nativeElement);
    // const _emit = card.emit;
    // card.emit = (...args) => {
    //   console.log('card:emit', ...args);
    //   return _emit(...args);
    // }
    this.element.on('change', this.onChangeHandler);
    this.element.on('blur', this.onBlurHandler);
    // this.element.on('focus', (...args)=> console.log('el:focus', ...args))
  }

  public ngOnDestroy(): void {
    if (this.element) {
      console.dir(this.element);
      // this.element.destroy();
      this.element.remove();
    }
  }

  private onBlurHandler(...args: []): void {
    // console.log('el:onBlurHander', ...args);
    if (this.cvaTouchedListener) {
      this.cvaTouchedListener();
    }
  }

  private onChangeHandler(event: any): void {
    this.valid = event.valid;
    this.validatorService.resetSecuredErrors();
    this.state = {
      number: event.number && { valid: event.number.valid, empty: event.number.empty },
      cvv: event.cvv && { valid: event.cvv.valid, empty: event.cvv.empty },
      expiry: event.expiry && { valid: event.expiry.valid, empty: event.expiry.empty },
    };

    // console.log('event', event, this.state);

    if (this.cvaChangeListener) {
      this.cvaChangeListener(this.getMaskedValue(event));
    }
    // console.log('el:change', event, this.getMaskedValue(event));
  }

  public writeValue(obj: any): void {
    // do nothing, we can't write value to recurly sensitive fields
  }

  public registerOnChange(fn: any): void {
    this.cvaChangeListener = fn;
  }

  public registerOnTouched(fn: any): void {
    this.cvaTouchedListener = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {}

  private getMaskedValue(event: any): string | null {
    if (!event.valid) {
      return '';
    }

    if (this.type === 'card') {
      return `${event.firstSix} ****** ${event.lastFour}`;
    }

    if (event.length) {
      return Array(event.length)
        .fill('*')
        .join('');
    }

    return '';
  }

  private async createElement(
    type: TRecurlyElementType,
    config?: CardElementOptions | IndividualElementOptions,
  ): Promise<IndividualElement | CardElement> {
    const elements = await this.recurlyElements.getElements();
    switch (type) {
      case 'card':
        return elements.CardElement(config as CardElementOptions);

      case 'number':
        return elements.CardNumberElement(config as IndividualElementOptions);

      case 'month':
        return elements.CardMonthElement(config as IndividualElementOptions);

      case 'year':
        return elements.CardYearElement(config as IndividualElementOptions);

      case 'cvv':
        return elements.CardCvvElement(config as IndividualElementOptions);
    }
  }

  private getElementConfig(): IndividualElementOptions {
    return {
      style: {
        fontFamily,
        fontColor,
        fontSize,
        placeholder: {
          color: placeholderColor,
          content: this.placeholder,
        },
      },
    };
  }

  private getCardConfig(): CardElementOptions {
    return {
      style: {
        fontFamily,
        fontColor,
        fontSize,
        placeholder: {
          color: placeholderColor,
          content: {
            number: this.placeholder,
          },
        },
      },
    };
  }
}
