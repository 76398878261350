import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'price',
})
export class PricePipe implements PipeTransform {
  transform(value: number): string {
    const price = roundWithPrecision(value, 2);
    return `${price < 0 ? '-' : ''}$${Math.abs(price).toFixed(2)}`;
  }
}

/**
 * example:
 * roundWithPrecision(1.005, 2) will be rounded to 1.01
 *
 * issue: 1.005 * 100 will be 1.499999.... (its JavaScript)
 * so we need to add Number.EPSILON to get the correct rounding
 */
export function roundWithPrecision(num: number, precision: number): number {
  const multiplier = Math.pow(10, precision);
  return Math.round((num + Number.EPSILON) * multiplier) / multiplier;
}
