<div *ngIf="displaySurvey$ | async" class="modal-backdrop">
  <div class="modal">
    <h3>
      Thank you for giving us a try, {{ orderState.getCalculatedShippingAddress()?.firstName }}. We would appreciate it
      if you could answer a couple of questions for us while we get your order ready.
    </h3>
    <div>
      <form class="hs-form" [formGroup]="hubspotForm" (ngSubmit)="onFormSubmit()">
        <div class="wrapper">
          <div class="form-entry">
            <p>Q1. How many vehicles are in your fleet?</p>
            <input formControlName="numOfVehicles" placeholder="Number" />
          </div>
          <div class="form-entry">
            <p>Q2. For which purpose are you tracking your vehicle(s)?</p>
            <select formControlName="personalVsBusiness" placeholder="Select One">
              <option value="" disabled selected>Select one</option>
              <option>Personal</option>
              <option>Business</option>
            </select>
          </div>
          <label
            *ngIf="
              hubspotForm.controls['numOfVehicles'].invalid &&
              (hubspotForm.controls['numOfVehicles'].dirty || hubspotForm.controls['numOfVehicles'].touched)
            "
            class="error"
          >
            Please enter a valid number for Q1.
          </label>
        </div>
        <button
          class="submit-btn"
          type="submit"
          [disabled]="!hubspotForm.valid"
          [ngClass]="!hubspotForm.valid ? 'disabled' : ''"
        >
          Submit
        </button>
      </form>
    </div>
  </div>
</div>
