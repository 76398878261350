import { Component, OnInit } from '@angular/core';
import { OrderStateService } from 'src/app/core/service/order-state.service';
import { TRegisterErrors } from 'src/app/core/types/type';
import { Location } from '@angular/common';

@Component({
  selector: 'app-error-details',
  templateUrl: './error-details.component.html',
  styleUrls: ['./error-details.component.scss'],
})
export class ErrorDetailsComponent implements OnInit {
  public error!: TRegisterErrors | null;

  constructor(private orderState: OrderStateService, readonly location: Location) {}

  public ngOnInit(): void {
    this.error = this.orderState.registerError();
  }

  public onBackPressed(): void {
    this.location.back();
  }
}
