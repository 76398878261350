import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AbstractEndpoints } from './abstract.endpoints';

const REGISTRATION_URL = '{apiUrl}/fleets/fulfillment/registrations';

export interface IRegistrationAddressDto {
  FirstName: string;
  LastName: string;
  Phone: string;
  Company: string;
  Street1: string;
  Street2: string;
  City: string;
  Region: string;
  PostalCode: string;
  Country: string;
}

export interface IRegistrationPostReqDto {
  ClientId: string;
  TokenId: string;
  FirstName: string;
  LastName: string;
  Email: string;
  PhoneNumber: string;
  CompanyName: string;
  PlanCode: string;
  CouponCode: string;
  VendorFulfillmentCode?: string;
  BillingAddress: IRegistrationAddressDto;
  ShippingAddress: IRegistrationAddressDto;
  DeviceCount: number;
  LeadSource: string;
  CaptchaInformation: {
    token: string;
    action: string;
  };
}
export interface IRegistrationPostResDto {
  captchaInformation: ICaptchaInformationRes;
}

export interface ICaptchaInformationReq {
  token: string;
  action: string;
}

export interface ICaptchaInformationRes {
  token: string;
  action: string;
  isValid: boolean;
  invalidReason: string;
  reason: string;
  score: number;
}

@Injectable({ providedIn: 'root' })
export class RegistrationEndpoints extends AbstractEndpoints {
  constructor(private httpClient: HttpClient) {
    super();
  }

  public registration(body: IRegistrationPostReqDto): Observable<IRegistrationPostResDto> {
    return this.httpClient.post<IRegistrationPostResDto>(this.createUrl(REGISTRATION_URL), { ...body });
  }
}
