<div class="order-summary">
  <div *ngIf="orderState.isLoading()" class="blackout blackout-white">
    <div class="spinner"></div>
  </div>

  <!-- START OF VWO BLOCK -->

  <div class="vwo-order-summary-body">
    <div *ngIf="orderState.isLoading()" class="blackout blackout-white">
      <div class="spinner"></div>
    </div>

    <h2 class="vwo-order-summary-title">
      Pricing - Starting
      {{ orderState.isLoading() ? '' : (orderState.firstBillingDate() | date: 'longDate') }}
    </h2>

    <div class="vwo-order-summary-sub-title">
      Your card will not be charged until the
      {{ orderState.trialDaysCount() }} day free trial is over.
    </div>

    <div class="vwo-billing-period-wrapper" [class.tryForce]="orderState.checkoutType === 'tryForce'">
      <h4 class="vwo-billing-title">Select a billing cycle</h4>
      <label class="switch-toggle">
        Monthly
        <div class="slider-wrapper">
          <input
            type="checkbox"
            [checked]="orderState.billingPeriod() === 'annual'"
            (change)="onBillingPeriodChanged($any($event)?.target?.checked)"
          />
          <span class="slider round"></span>
        </div>
        Yearly
      </label>
    </div>

    <!-- Select devices count -->
    <ng-container *ngIf="orderState.checkoutType === 'getForce'">
      <label class="vwo-vehicles-count-selector">
        <span>I want to track </span>
        <app-select
          class="vwo-vehicles-track-select"
          [ngModel]="orderState.devicesCount()"
          (ngModelChange)="onDeviceCountChanged($event)"
          [disabled]="isVendorSpecific"
        >
          <option *ngFor="let i of devicesCountList" [value]="i">{{ i }}</option>
        </app-select>
        <span> vehicles.</span>
      </label>

      <div class="vwo-call-us">
        <span>Fleet larger than 50 vehicles? </span>
        <span
          class="vwo-highlighted text-lighter"
          fixedHintType="tooltip"
          [position]="'top-middle'"
          [mdUpWidth]="275"
          [mdDownWidth]="190"
          [autoPositioning]="false"
          [keepOpenOnHoverOverContent]="true"
          [hint]="largerFleetTpl"
        >
          <span>Call us</span>
          <ng-template #largerFleetTpl>
            <p class="larger-fleet-tooltip">
              If you'd like to start a trial for more than 50 vehicles, please call us at
              <a href="tel:8068558255">(806) 855-8255</a>
            </p>
          </ng-template>
        </span>
      </div>
    </ng-container>
    <!-- // END: Select devices count -->

    <!-- Order Table Common -->
    <div class="vwo-order-table sm-hide">
      <div class="vwo-row-title">Subscription:</div>
      <div class="vwo-table-row vwo-first-row">
        <div class="vwo-col-icon">
          <img class="vwo-icon" src="/assets/icons/app.png" alt="app" />
        </div>
        <div class="vwo-col-item vwo-device-title">{{ orderState.devicesCount() }} Vehicle Tracking Plan</div>
        <div class="vwo-col-price color-gray text-bold">
          <span>{{ orderState.devicesCount() }} x </span>
          <span>{{ orderState.oneVehicleTrackingPrice() | price }}</span>
        </div>
        <div class="vwo-col-total color-blue text-bold">
          {{ orderState.totalVehiclesTrackingPriceWithDiscount() | price }}
        </div>
      </div>
      <div class="vwo-row-title">Hardware:</div>
      <div class="vwo-table-row vwo-last-row border-bottom">
        <div class="vwo-col-icon">
          <img class="vwo-icon" src="/assets/icons/device.png" alt="device" />
        </div>
        <div class="vwo-col-item vwo-device-title">{{ orderState.devicesCount() }} GPS Tracking Device(s)</div>
        <div class="vwo-col-price color-gray text-bold"></div>
        <div class="vwo-col-total color-blue discount" [class.discount]="orderState.hasDevicesDiscount()">Included</div>
      </div>
    </div>

    <!-- Order table small -->
    <div class="vwo-order-table sm-show">
      <div class="vwo-row-title">Subscription:</div>
      <div class="vwo-table-row">
        <div class="vwo-col-icon">
          <img class="vwo-icon" src="/assets/icons/app.png" alt="app" />
        </div>
        <div class="vwo-col-item vwo-col-name">{{ orderState.devicesCount() }} Vehicle Tracking Plan</div>
        <div class="vwo-col-price color-gray">
          <span>{{ orderState.devicesCount() }} x </span>
          <span>{{ orderState.oneVehicleTrackingPrice() | price }}</span>
        </div>
        <div class="vwo-col-total color-blue text-bold">
          {{ orderState.totalVehiclesTrackingPriceWithDiscount() | price }}
        </div>
      </div>

      <div class="vwo-row-title">Hardware:</div>
      <div class="vwo-table-row">
        <div class="vwo-col-icon">
          <img class="vwo-icon" src="/assets/icons/device.png" alt="device" />
        </div>
        <div class="vwo-col-item vwo-col-name">{{ orderState.devicesCount() }} GPS Tracking Device(s)</div>
        <div class="vwo-col-price color-gray"></div>
        <div class="vwo-col-total color-blue discount" [class.discount]="orderState.hasDevicesDiscount()">Included</div>
      </div>
    </div>
    <!-- //#end Order table small -->

    <div class="vwo-order-table">
      <div class="vwo-table-row">
        <div class="vwo-col-item text-lighter sm-fs16">
          {{ 'Monthly Subtotal' | annual: 'Yearly Subtotal' }}
        </div>
        <div class="vwo-col-total color-blue text-bold sm-fs16">
          {{ orderState.subTotalPriceWithDiscount() | price }}
        </div>
      </div>

      <div class="vwo-table-row vwo-promo-row sm-fs16">
        <div
          *ngIf="orderState.promoCodeData() as promoCode; else tplPromoNotAppliedVWO"
          class="vwo-col-item text-lighter"
        >
          Promo Code:
          <span class="promo" [class.invalid-promo]="promoCode.type === 'invalid'">
            {{ promoCode.couponCode }}
          </span>
          <div *ngIf="promoCode.type === 'invalid'; else tplPromoAppliedVWO" class="invalid-label">
            Invalid promo code
            <span class="invalid-icon"></span>
          </div>
          <ng-template #tplPromoAppliedVWO>
            <span class="promo-applied">applied</span>
          </ng-template>
        </div>
        <ng-template #tplPromoNotAppliedVWO>
          <div class="vwo-col-item text-lighter">Promo Code</div>
        </ng-template>
        <div class="vwo-col-promo-code">
          <app-input-promo (apply)="onApplyPromo($event)"></app-input-promo>
        </div>
      </div>

      <!-- Discount details -->
      <div
        *ngIf="orderState.promoCodeData() && $any(orderState.promoCodeData()).type !== 'invalid'"
        class="vwo-table-row"
      >
        <div class="vwo-col-item text-lighter sm-fs16">
          Discount:
          <ng-container [ngSwitch]="$any(orderState.promoCodeData()).type">
            <span *ngSwitchCase="'percentage'" class="discount-details">
              <span *ngIf="!isVendorSpecific; else byDeviceTplVWO"
                >{{ $any(orderState.promoCodeData()).discountPercentage }}% of subscription cost</span
              >
              <ng-template #byDeviceTplVWO>
                <span>{{ orderState.getVendorSpecificDiscountLabel() }}</span>
              </ng-template>
            </span>
            <span *ngSwitchCase="'fixed'" class="discount-details">
              {{ $any(orderState.promoCodeData()).fixedAmount | price }} of subscription cost
            </span>
            <span *ngSwitchCase="'free'" class="discount-details">
              Free for the first
              {{ $any(orderState.promoCodeData()).trialLength }}
              {{ $any(orderState.promoCodeData()).trialUnit === 'day' ? 'days' : 'months' }}
            </span>
          </ng-container>
        </div>
        <div *ngIf="!!orderState.getDiscount()" class="vwo-col-total color-blue text-bold sm-fs16">
          {{ -1 * orderState.getDiscount() | price }}
        </div>
      </div>
      <!-- Discount details -->

      <div class="vwo-table-row">
        <div class="vwo-col-item text-lighter sm-fs16">Sales Tax</div>
        <div class="vwo-col-total color-blue text-bold sm-fs16">
          {{ orderState.taxesPrice() | price }}
        </div>
        <div *ngIf="(orderState.state() | async)?.loadTaxesFailed" class="vwo-col-item col-error">
          Taxes loading failed <span class="invalid-icon"></span>
        </div>
      </div>

      <div class="vwo-table-row vwo-shipping-row border-bottom sm-fs16">
        <div class="vwo-col-item text-lighter">
          <span
            class="vwo-highlighted text-lighter"
            fixedHintType="tooltip"
            [position]="'bottom-middle'"
            [mdUpWidth]="275"
            [mdDownWidth]="190"
            [autoPositioning]="true"
            [hint]="shippingTplVWO"
          >
            <span>Free Shipping</span>
            <ng-template #shippingTplVWO>
              <p class="tooltip-content">Shipping is free for all orders during this limited-time offer.</p>
            </ng-template>
          </span>
        </div>
      </div>

      <div class="vwo-table-row">
        <div class="vwo-col-item text-bold sm-fs16">Due Today</div>
        <div class="vwo-col-total color-blue text-bold sm-fs16">$0.00</div>
      </div>
    </div>
  </div>

  <!-- END OF VWO BLOCK -->

  <ng-content></ng-content>
</div>
