import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  Optional,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { IAddressDetails } from 'src/app/core/models/i-order-state.model';
import { RecurlyValidatorsService } from 'src/app/shared/components/recurly/services/recurly-validators.service';
import { RequiredAfterTrimmingValidator } from '../../../../core/forms/validators/required-after-trimming.validator';

// export interface IFormValue {
//   firstName: string;
//   lastName: string;
//   address1: string;
//   address2: string;
//   city: string;
//   state: string;
//   postalCode: string;
//   country: 'US';
// }
@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
})
export class AddressFormComponent implements OnDestroy, AfterViewChecked {
  @Input()
  public placeholders = {
    companyName: 'Company',
    firstName: 'First name',
    lastName: 'Last name',
    address1: 'Street address',
    address2: 'Street address line 2',
    city: 'City',
    postalCode: 'ZIP code',
  };

  @Input()
  public set disabled(isDisabled: boolean) {
    if (isDisabled) {
      this.addressForm.disable();
    } else {
      this.addressForm.enable();
    }
  }

  public addressForm!: FormGroup;

  public maxLengthList = {
    companyName: 100,
    firstName: 50,
    lastName: 50,
    address1: 50,
    address2: 50,
    city: 50,
    postalCode: 5,
  };

  private destroy$ = new Subject<void>();

  @ViewChild('companyName', { static: true })
  public companyNameElRef!: ElementRef<HTMLInputElement>;

  constructor(
    @Optional()
    private recurlyValidators: RecurlyValidatorsService,
    private changeDetector: ChangeDetectorRef,
  ) {
    const dummy = () => null;
    this.addressForm = new FormGroup({
      companyName: new FormControl('', [
        RequiredAfterTrimmingValidator,
        Validators.maxLength(this.maxLengthList.companyName),
      ]),
      firstName: new FormControl('', [
        RequiredAfterTrimmingValidator,
        Validators.maxLength(this.maxLengthList.firstName),
        this.recurlyValidators?.createValidator('first_name') || dummy,
      ]),
      lastName: new FormControl('', [
        RequiredAfterTrimmingValidator,
        Validators.maxLength(this.maxLengthList.lastName),
        this.recurlyValidators?.createValidator('last_name') || dummy,
      ]),
      address1: new FormControl('', [
        RequiredAfterTrimmingValidator,
        Validators.maxLength(this.maxLengthList.address1),
        this.recurlyValidators?.createValidator('address1') || dummy,
      ]),
      address2: new FormControl('', [
        Validators.maxLength(this.maxLengthList.address2),
        this.recurlyValidators?.createValidator('address2') || dummy,
      ]),
      country: new FormControl({ value: 'US', disabled: true }, Validators.required),
      city: new FormControl('', [
        RequiredAfterTrimmingValidator,
        Validators.maxLength(this.maxLengthList.city),
        this.recurlyValidators?.createValidator('city') || dummy,
      ]),
      postalCode: new FormControl('', [
        Validators.required,
        Validators.maxLength(this.maxLengthList.postalCode),
        Validators.minLength(5),
        this.recurlyValidators?.createValidator('postal_code') || dummy,
      ]),
      state: new FormControl('', [Validators.required, this.recurlyValidators?.createValidator('state') || dummy]),
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
  }

  public ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }

  public isValid(): boolean {
    return this.addressForm.valid;
  }

  public setValue(address: IAddressDetails): void {
    this.addressForm.setValue(address);
  }

  public revalidate(): void {
    Object.keys(this.addressForm.controls).forEach((field) => {
      const control = this.addressForm.get(field);
      control?.updateValueAndValidity();
    });
  }

  public getValue(): IAddressDetails {
    return this.prepareFormValue(this.addressForm.value);
  }

  public valueChanges(): Observable<IAddressDetails> {
    return this.addressForm.valueChanges.pipe(
      map((formValue) => this.prepareFormValue(formValue)),
      takeUntil(this.destroy$),
    );
  }

  private prepareFormValue(value: IAddressDetails): IAddressDetails {
    return {
      ...value,
      country: 'US',
    };
  }
}
