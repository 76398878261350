import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AddressFormComponent } from '../address-form/address-form.component';

// TODO not used
@Component({
  selector: 'app-address-form-errors',
  templateUrl: './address-form-errors.component.html',
  // styleUrls: ['./address-form-errors.component.scss']
})
export class AddressFormErrorsComponent implements OnInit {
  @Input()
  addressForm?: AddressFormComponent | undefined;

  public formGroup: FormGroup = new FormGroup({});

  constructor() {}

  ngOnInit(): void {
    this.formGroup = this.addressForm?.addressForm || this.formGroup;
  }
}
