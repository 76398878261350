import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { HubSpotEndpoints, IFormFieldData, IHubspotFormDto } from 'src/app/core/endpoints/hubspot.endpoints';
import { OrderStateService } from 'src/app/core/service/order-state.service';
import { CookieService } from 'ngx-cookie-service';
import { Title } from '@angular/platform-browser';

export enum HubspotFormField {
  email = 'email',
  numOfVehicles = 'number_of_vehicles',
  personalVsBusiness = 'personal_vs__business_vehicles',
}
@Component({
  selector: 'app-survey-modal',
  templateUrl: './survey-modal.component.html',
  styleUrls: ['./survey-modal.component.scss'],
})
export class SurveyModalComponent {
  public hubspotForm = new FormGroup({
    numOfVehicles: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
    personalVsBusiness: new FormControl('', Validators.required),
  });

  public displaySurvey$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    public orderState: OrderStateService,
    private cookieService: CookieService,
    private hubspotEndpoints: HubSpotEndpoints,
    private titleService: Title,
  ) {}

  public onFormSubmit = () => {
    this.submitFormRequest();
    this.displaySurvey$.next(false);
  };

  private submitFormRequest = () => {
    const fields = [];
    for (const name of Object.keys(HubspotFormField)) {
      let value = this.hubspotForm.get(name)?.value;
      if (name === 'email') {
        value = this.orderState.getEmail();
      }
      fields.push({
        objectTypeId: '0-1',
        name: HubspotFormField[name as keyof typeof HubspotFormField],
        value,
      } as IFormFieldData);
    }
    const formData = {
      fields,
      context: {
        pageName: this.titleService.getTitle(),
        pageUri: window.location.href,
        hutk: this.cookieService.get('hubspotutk'),
      },
    } as IHubspotFormDto;

    this.hubspotEndpoints.submitForm(formData).subscribe({
      next: (data) => console.log('Form submitted:', data),
      error: (data) => console.log('Erorr submitting:', data),
    });
  };
}
