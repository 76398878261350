import {
  AfterContentInit,
  Component,
  ContentChildren,
  forwardRef,
  Injector,
  Input,
  OnInit,
  QueryList,
} from '@angular/core';
import { ControlValueAccessor, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { debounce } from 'src/app/utils/decorators/debounce';
import { SelectDropdownOptionComponent } from './select-dropdown-option/select-dropdown-option.component';

@Component({
  selector: 'app-select-dropdown',
  templateUrl: './select-dropdown.component.html',
  styleUrls: ['./select-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectDropdownComponent),
      multi: true,
    },
  ],
})
export class SelectDropdownComponent implements OnInit, AfterContentInit, ControlValueAccessor {
  @ContentChildren(SelectDropdownOptionComponent)
  public contentChildren!: QueryList<SelectDropdownOptionComponent>;

  @Input()
  public value: any;

  @Input()
  public placeholder = '';

  public title = '';

  public control!: NgControl;

  private onChangeFn!: (val: any) => void;
  private onTouchFn!: () => void;

  constructor(private injector: Injector) {}

  public ngOnInit(): void {
    this.title = this.placeholder;
    this.control = this.injector.get(NgControl);
  }

  public ngAfterContentInit(): void {
    for (const optComponent of this.contentChildren) {
      if (optComponent.value === this.value) {
        this.title = optComponent.getTitle();
      }
      optComponent.onselect.subscribe((opt: SelectDropdownOptionComponent) => {
        this.selectOption(opt);
      });
    }
  }

  @debounce()
  public onBlurDropDown(): void {
    if (this.onTouchFn) {
      this.onTouchFn();
    }
  }

  private selectOption(option: SelectDropdownOptionComponent): void {
    this.title = option.getTitle();
    this.value = option.value;
    if (this.onChangeFn) {
      this.onChangeFn(this.value);
    }
  }

  private setValue(value: any): void {
    this.value = value;
    if (this.contentChildren) {
      for (const optComponent of this.contentChildren) {
        if (optComponent.value === this.value) {
          this.title = optComponent.getTitle();
          return;
        }
      }
    }

    this.title = this.placeholder;
  }

  public writeValue(val: any): void {
    this.setValue(val);
  }

  public registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouchFn = fn;
  }
}
