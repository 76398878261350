import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AbstractEndpoints } from './abstract.endpoints';

const CODES_URL = '{apiUrl}/fleets/fulfillment/codes';

export interface ICodeResponseDto {
  couponCode: string;
  numberOfDevices: number;
}

export interface ICodeRequestDto {
  fulfillmentCode: string;
  clientId: string;
}

@Injectable({ providedIn: 'root' })
export class CodesEndpoints extends AbstractEndpoints {
  constructor(private httpClient: HttpClient) {
    super();
  }

  public validateVendorCode(codeReqDto: ICodeRequestDto): Observable<ICodeResponseDto> {
    return this.httpClient.get<ICodeResponseDto>(this.createUrl(CODES_URL), {
      params: { ...codeReqDto },
    });
  }
}
