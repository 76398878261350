import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CodesEndpoints, ICodeResponseDto } from '../endpoints/codes.endpoints';

@Injectable({ providedIn: 'root' })
export class VendorInfoLoader {
  constructor(private endpoints: CodesEndpoints) {}

  public loadVendorInfo(fulfillmentCode: string): Observable<ICodeResponseDto> {
    return this.endpoints.validateVendorCode({
      fulfillmentCode,
      clientId: this.endpoints.getClientId(),
    });
  }
}
