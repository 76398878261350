import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, finalize, map, switchMap, tap } from 'rxjs/operators';
import { ITaxesRate, RecurlyService } from 'src/app/shared/components/recurly/services/recurly.service';
import { PriceLoader } from '../loaders/price.loader';
import { billingPeriodToPlanCode } from '../loaders/utils/transform';
import { IAddressDetails, TBillingPeriod } from '../models/i-order-state.model';
import { TPlanPriceMapModel } from '../models/ibase-price.model';
import { IPromoCodeFixedDiscount, IPromoCodePercentageDiscount, TPromoCodeModel } from '../models/ipromo-code.model';
import { TRegisterErrors } from '../types/type';
import { CheckoutTypeService, TCHECKOUT_TYPE } from './checkout-type.service';

const DEFAULT_SUBS_PLAN_PRICES: TPlanPriceMapModel = {
  monthly: {
    shippingRate: 5,
    freeShippingQty: 0,
    costPerDevice: 18,
  },
  annual: {
    shippingRate: 5.0,
    freeShippingQty: 0,
    costPerDevice: 216,
  },
};

const DEFAULT_TRIAL_DAYS = 30;
export interface IOrderStateModel {
  email: string;
  phone: string;
  howDidYouFindUs?: string;
  howDidYouFindUsComment?: string;
  shippingAddress: IAddressDetails | undefined;
  recurlyToken: string;
  sameBillingAndShippingAddress: boolean;
  billingAddress: IAddressDetails | undefined;
  devicesCount: number;
  billingPeriod: TBillingPeriod;
  isOrderCompleted: boolean;
  subscriptionPlanPrice: TPlanPriceMapModel;
  subTotalPriceFromPlatform: number;
  promoCodeKey: string;
  promoCodeData: TPromoCodeModel | null;
  firstBillingDate: Date;
  taxesRate: number;
  loadTaxesFailed: boolean;
  defaultTrialDays: number;
  errorRegister: TRegisterErrors | null;
  isValidShippingDetails: boolean;
  isValidBillingAddress: boolean;
  vendorFulfillmentCode: string | undefined;
  redirect: string | undefined;
  checkoutType: TCHECKOUT_TYPE;
}

const INITIAL_STATE: IOrderStateModel = {
  phone: '',
  email: '',
  devicesCount: 2,
  billingPeriod: 'monthly',
  promoCodeKey: '',
  isOrderCompleted: false,
  subscriptionPlanPrice: DEFAULT_SUBS_PLAN_PRICES,
  subTotalPriceFromPlatform: 0,
  promoCodeData: null,
  firstBillingDate: new Date(),
  taxesRate: 0,
  loadTaxesFailed: false,
  defaultTrialDays: DEFAULT_TRIAL_DAYS,
  errorRegister: null,
  sameBillingAndShippingAddress: true,
  isValidBillingAddress: false,
  isValidShippingDetails: false,
  shippingAddress: undefined,
  billingAddress: undefined,
  recurlyToken: '',
  vendorFulfillmentCode: undefined,
  redirect: undefined,
  checkoutType: 'getForce',
};

@Injectable({
  providedIn: 'root',
})
export class OrderStateService {
  private state$!: BehaviorSubject<IOrderStateModel>;

  private loadingState = {
    plan: false,
    promo: false,
    subTotalPrice: false,
    taxes: false,
  };

  private validVendorCouponCodes: Set<string> = new Set<string>(['ma20']);

  constructor(
    //
    private priceLoader: PriceLoader, //
    private recurlyService: RecurlyService,
    private checkoutTypeService: CheckoutTypeService,
  ) {
    this.state$ = new BehaviorSubject(INITIAL_STATE);
    this._initCheckoutType();

    this._loadPricePlanMap();
    this._initLoadSubtotalPrice();

    this._initUpdateFirstBillingDate();
    this._initLoadingTrialPeriod();

    this._initLoadPromoCodeData();
    this._initLoadTaxesRate();
  }

  public resetState(): void {
    this.updateState(INITIAL_STATE);
    this._initCheckoutType();
  }

  private _initLoadingTrialPeriod(): void {
    this.state$
      .pipe(
        map((state) => state.billingPeriod),
        distinctUntilChanged(),
        switchMap((period) => {
          return this.recurlyService.loadPlan(billingPeriodToPlanCode(period)).pipe(
            map((plan) => (plan.trial && plan.trial.interval === 'days' ? plan.trial.length : 0)),
            catchError(() => of(null)),
          );
        }),
        filter((notNull) => notNull !== null),
      )
      .subscribe((trialDays) => {
        this._updateState({
          defaultTrialDays: trialDays as number,
        });
      });
  }

  private _initUpdateFirstBillingDate(): void {
    this.state$
      .pipe(
        distinctUntilChanged(
          ({ defaultTrialDays: xa, promoCodeData: xb }, { defaultTrialDays: ya, promoCodeData: yb }) =>
            xa === ya && xb === yb,
        ),
      )
      .subscribe(({ defaultTrialDays, promoCodeData }) => {
        const firstBillingDate = new Date();

        if (promoCodeData?.type === 'free') {
          switch (promoCodeData?.trialUnit) {
            case 'month':
              firstBillingDate.setMonth(firstBillingDate.getMonth() + promoCodeData.trialLength);
              break;
            case 'day':
              firstBillingDate.setDate(firstBillingDate.getDate() + promoCodeData.trialLength);
              break;
          }
        } else {
          firstBillingDate.setDate(firstBillingDate.getDate() + defaultTrialDays);
        }

        this._updateState({ firstBillingDate });
      });
  }

  private _initLoadSubtotalPrice(): void {
    this.state$
      .pipe(
        distinctUntilChanged(
          ({ billingPeriod: xa, devicesCount: xb }, { billingPeriod: ya, devicesCount: yb }) => xa === ya && xb === yb,
        ),
        tap(() => {
          this.loadingState.subTotalPrice = true;
          this._updateState({ subTotalPriceFromPlatform: 0 });
        }),
        switchMap(({ billingPeriod, devicesCount }) =>
          this.priceLoader.loadSubTotalPrice(billingPeriod, devicesCount).pipe(
            catchError(() => of(0)),
            finalize(() => (this.loadingState.subTotalPrice = false)),
          ),
        ),
      )
      .subscribe((subTotalPriceFromPlatform) => this._updateState({ subTotalPriceFromPlatform }));
  }

  private _initLoadPromoCodeData(): void {
    this.state$
      .pipe(
        distinctUntilChanged(
          ({ billingPeriod: xa, promoCodeKey: xb }, { billingPeriod: ya, promoCodeKey: yb }) => xa === ya && xb === yb,
        ),
        filter(({ billingPeriod, promoCodeKey }) => !!billingPeriod && !!promoCodeKey),
        tap(() => {
          this.loadingState.promo = true;
          this._updateState({ promoCodeData: null });
        }),
        switchMap(({ billingPeriod, promoCodeKey }) =>
          this.priceLoader.loadPromoCode(billingPeriod, promoCodeKey as string).pipe(
            catchError(() => of(null)),
            finalize(() => (this.loadingState.promo = false)),
          ),
        ),
      )
      .subscribe((promoCodeData) => this._updateState({ promoCodeData }));
  }

  private _initLoadTaxesRate(): void {
    this.state$
      .pipe(
        map((state) => {
          if (state.checkoutType === 'tryForce') {
            return state.shippingAddress;
          }
          return state.billingAddress;
        }),
        distinctUntilChanged((prev, next) => {
          return (!prev?.postalCode && !next?.postalCode) || prev?.postalCode === next?.postalCode;
        }),
        debounceTime(500),
        switchMap((address) => {
          if (!address || !address.postalCode || !address.country) {
            return of({
              taxesRate: 0,
              loadTaxesFailed: false,
            });
          }
          this.loadingState.taxes = true;
          return this.recurlyService.loadTaxesRate(address?.postalCode as string, address?.country as string).pipe(
            finalize(() => (this.loadingState.taxes = false)),
            map<ITaxesRate | null, Partial<IOrderStateModel>>((taxesRate) => ({
              taxesRate: (taxesRate && taxesRate.rate) || 0,
              loadTaxesFailed: false,
            })),
            catchError(() =>
              of<Partial<IOrderStateModel>>({
                taxesRate: 0,
                loadTaxesFailed: true,
              }),
            ),
          );
        }),
      )
      .subscribe((taxesUp) => this._updateState(taxesUp));
  }

  private _initCheckoutType(): void {
    const checkoutType = this.checkoutTypeService.getCheckoutType();
    const devicesCount = checkoutType === 'tryForce' ? 1 : 2;
    this._updateState({
      checkoutType,
      devicesCount,
    });
  }

  private _updateState(updates: Partial<IOrderStateModel>): void {
    this.state$.next({
      ...this.state$.value,
      ...updates,
    });
  }

  public updateState(updates: Partial<IOrderStateModel>): void {
    this._updateState(updates);
  }

  public state(): Observable<IOrderStateModel> {
    return this.state$;
  }

  public stateValue(): IOrderStateModel {
    return this.state$.getValue();
  }

  public setShippingAddress(shippingAddress: IAddressDetails, isValidShippingDetails: boolean): void {
    this._updateState({ shippingAddress, isValidShippingDetails });
  }

  public setBillingAddress(billingAddress: IAddressDetails | undefined, isValidBillingAddress: boolean): void {
    this._updateState({ billingAddress, isValidBillingAddress });
  }

  public setBillingPeriod(billingPeriod: TBillingPeriod): void {
    this._updateState({
      billingPeriod,
    });
    if (this.promoCodeData()) {
      this.applyPromoCode((this.promoCodeData() as TPromoCodeModel).couponCode);
    }
  }

  public setDevicesCount(devicesCount: number): void {
    this._updateState({
      devicesCount,
    });
  }

  public setCommonDetails(details: {
    email: string;
    phone: string;
    howDidYouFindUs: string;
    howDidYouFindUsComment: string;
  }): void {
    this._updateState({
      ...details,
    });
  }

  public setRegisterError(errorRegister: TRegisterErrors): void {
    this._updateState({ errorRegister });
  }

  public markOrderAsCompleted(): void {
    this._updateState({
      isOrderCompleted: true,
    });
  }

  public isOrderCompleted(): boolean {
    return this.state$.value.isOrderCompleted;
  }

  public registerError(): TRegisterErrors | null {
    return this.state$.value.errorRegister;
  }

  public firstBillingDate(): Date {
    return this.state$.value.firstBillingDate;
  }

  public firstBillingDateVWO(): Date {
    const firstBillingDate = new Date();
    firstBillingDate.setDate(firstBillingDate.getDate() + (this.state$.value.defaultTrialDays || 30));
    return firstBillingDate;
  }

  public isLoading(): boolean {
    return (
      this.loadingState.plan || this.loadingState.subTotalPrice || this.loadingState.promo || this.loadingState.taxes
    );
  }

  public applyPromoCode(promoCodeKey: string): void {
    this._updateState({
      promoCodeKey,
    });
  }

  // public shippingDetails(): IShippingDetails | undefined {
  //   return this.state$.value.shippingDetails;
  // }

  public shippingAddress(): IAddressDetails | undefined {
    return this.stateValue().shippingAddress;
  }
  public billingAddress(): IAddressDetails | undefined {
    return this.stateValue().billingAddress;
  }
  public billingPeriod(): TBillingPeriod {
    return this.state$.value.billingPeriod;
  }

  public billingPeriodChanges(): Observable<TBillingPeriod> {
    return this.state$.pipe(
      map(({ billingPeriod }) => billingPeriod),
      distinctUntilChanged(),
    );
  }

  public devicesCount(): number {
    return this.state$.value.devicesCount;
  }

  public oneVehicleTrackingPrice(): number {
    return this.state$.value.subscriptionPlanPrice[this.billingPeriod()].costPerDevice;
  }

  public oneVehicleTrackingPricePerMonth(): number {
    return this.state$.value.subscriptionPlanPrice.monthly.costPerDevice;
  }

  public oneDevicePrice(): number {
    return 150; // const device price
  }

  public totalVehiclesTrackingPrice(): number {
    return this.state$.value.devicesCount * this.oneVehicleTrackingPrice();
  }

  public totalVehiclesTrackingPriceWithDiscount(): number {
    return this.totalVehiclesTrackingPrice();
  }

  public hasVehicleTrackingDiscount(): boolean {
    return false;
  }

  public totalDevicesPrice(): number {
    return this.state$.value.devicesCount * this.oneDevicePrice();
  }

  public totalDevicesPriceWithDiscount(): number {
    return 0;
  }

  public hasDevicesDiscount(): boolean {
    return true;
  }

  public subTotalPrice(): number {
    return (
      this.state$.value.subTotalPriceFromPlatform ||
      this.totalVehiclesTrackingPriceWithDiscount() + this.totalDevicesPriceWithDiscount()
    );
  }

  public subTotalPriceWithDiscount(): number {
    switch (this.promoCodeData()?.type) {
      case 'fixed':
        return this.subTotalPrice() - (this.promoCodeData() as IPromoCodeFixedDiscount).fixedAmount;
      case 'percentage':
        return (
          this.subTotalPrice() * (1 - (this.promoCodeData() as IPromoCodePercentageDiscount).discountPercentage / 100)
        );
    }
    return this.subTotalPrice();
  }

  public shippingPrice(): number {
    return 5;
    // if (this.state$.value.subscriptionPlanPrice[this.billingPeriod()].freeShippingQty < this.devicesCount()) {
    //   return 0;
    // }

    // return this.state$.value.subscriptionPlanPrice[this.billingPeriod()].shippingRate;
  }

  public shippingTotalPrice(): number {
    return 0;
    // return this.shippingPrice();
  }

  public taxesPrice(): number {
    return this.subTotalPriceWithDiscount() * this.state$.value.taxesRate;
  }

  public subTotalPriceWithTaxes(): number {
    return this.subTotalPriceWithDiscount() + this.taxesPrice();
  }

  public promoCodeData(): TPromoCodeModel | null {
    return this.state$.value.promoCodeData;
  }

  public getCalculatedShippingAddress(): IAddressDetails | undefined {
    const state = this.stateValue();
    if (state.sameBillingAndShippingAddress) {
      return this.checkoutType === 'tryForce' ? state.shippingAddress : state.billingAddress;
    }
    return state.shippingAddress;
  }
  public getCalculatedBillingAddress(): IAddressDetails | undefined {
    const state = this.stateValue();
    if (state.sameBillingAndShippingAddress) {
      return this.checkoutType === 'tryForce' ? state.shippingAddress : state.billingAddress;
    }
    return state.billingAddress;
  }
  public trialDaysCount(): number {
    const state = this.stateValue();
    if (state.promoCodeData?.type === 'free') {
      return state.promoCodeData?.trialLength;
    }

    return this.stateValue().defaultTrialDays;
  }

  public getVendorSpecificDiscountLabel(): string {
    const isValidVendorCode =
      !!this.state$.value.promoCodeData &&
      this.validVendorCouponCodes.has(this.state$.value.promoCodeData.couponCode.toLowerCase());
    const labelSuffix = isValidVendorCode
      ? ` - ${(this.promoCodeData() as IPromoCodePercentageDiscount).discountPercentage}% off`
      : '';
    return `Special Discount${labelSuffix}`;
  }

  public getDiscount(): number {
    return this.subTotalPrice() - this.subTotalPriceWithDiscount();
  }

  public getRedirectAddress(): string {
    return this.state$.value.redirect || '';
  }

  private _loadPricePlanMap(): void {
    this.loadingState.plan = true;
    this.priceLoader
      .loadSubsPlanPriceMap()
      .pipe(finalize(() => (this.loadingState.plan = false)))
      .subscribe((subscriptionPlanPrice) => {
        this._updateState({ subscriptionPlanPrice });
      });
  }

  public getEmail(): string {
    return this.state$.value.email || '';
  }

  public get checkoutType(): TCHECKOUT_TYPE {
    return this.state$.value.checkoutType;
  }
}
