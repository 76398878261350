import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CongratulationComponent } from '../view/order/pages/congratulation/congratulation.component';
import { ErrorDetailsComponent } from '../view/order/pages/error-details/error-details.component';
import { OrderSingleStepComponent } from '../view/order/pages/order-single-step/order-single-step.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: OrderSingleStepComponent,
      },
      {
        path: 'shipping/payment/congratulation',
        component: CongratulationComponent,
      },
      {
        path: 'shipping/payment/congratulation/singlevehicle',
        component: CongratulationComponent,
      },
      {
        path: 'shipping/payment/error',
        component: ErrorDetailsComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
