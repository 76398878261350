<app-common-layout>
  <div class="content">
    <div class="left-side">
      <h1 class="title"><ng-content select="page-title"></ng-content></h1>
      <h2 class="sub-title"><ng-content select="page-subtitle"></ng-content></h2>
      <div class="thank-you-message">
        <ng-content select="page-sub-content"></ng-content>
      </div>

      <ng-content></ng-content>
    </div>

    <div class="right-side">
      <div class="dots-background"></div>
      <img class="img-screen" src="/assets/images/desktop-screen.png" />
    </div>
  </div>
  <div class="actions">
    <ng-content select="app-layout-actions"></ng-content>
  </div>
</app-common-layout>
