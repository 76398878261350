<app-order-layout activeStep="order" [isVendorSpecific]="isVendorSpecific">
  <div *ngIf="isLoading || (emailTabSubj$ | async) === true || (phoneTabSubj$ | async) === true" class="blackout">
    <div class="spinner"></div>
  </div>
  <page-title *ngIf="checkoutType === 'tryForce'; else billingInformation">Shipping Information</page-title>

  <ng-template #whyWeAreAskingBillingInfoTplVWO>
    <div class="tooltip-content">
      <p>
        Your free trial of Force by Mojio includes one GPS tracking device for each one of your business vehicles. We
        need to know where to send these devices.
      </p>
    </div>
  </ng-template>

  <!-- Main Content #tplMainContent -->
  <form [formGroup]="orderDetailsForm" class="e2e-contacts-form">
    <app-input-wrapper>
      <input
        #emailInput
        appInputCtrl
        name="email"
        class="app-input"
        formControlName="email"
        type="email"
        placeholder="Email"
        (keydown.tab)="onHandleEmailTabPressed($event)"
      />
      <div *ngIf="orderDetailsForm.get('email')?.status !== 'PENDING'; else validatingEmailTpl" class="input-error">
        <div appFormError="email" errorName="required">Please enter email address.</div>
        <div appFormError="email" errorName="email">Please enter a valid email address.</div>
        <div appFormError="email" errorName="emailAsync">
          {{ orderDetailsForm.get('email')?.errors?.emailAsync }}
        </div>
      </div>
      <ng-template #validatingEmailTpl>
        <div class="input-validation-in-progress">
          Validating email address...
        </div>
      </ng-template>
    </app-input-wrapper>

    <app-input-wrapper>
      <input
        #phoneInput
        appInputCtrl
        name="phone"
        class="app-input phone-input"
        mask="000-000-0000"
        inputmode="numeric"
        formControlName="phone"
        type="text"
        placeholder="Phone"
        (keydown.tab)="onHandlePhoneTabPressed($event)"
      />
      <span class="area-code-icon"></span>
      <div *ngIf="orderDetailsForm.get('phone')?.status !== 'PENDING'; else validatingPhoneTpl" class="input-error">
        <div appFormError="phone" errorName="required">Please enter phone number.</div>
        <div appFormError="phone" errorName="mask">Phone number is too short</div>
        <div appFormError="phone" errorName="phoneAsync">
          {{ orderDetailsForm.get('phone')?.errors?.phoneAsync }}
        </div>
      </div>
      <ng-template #validatingPhoneTpl>
        <div class="input-validation-in-progress">
          Validating phone number...
        </div>
      </ng-template>
    </app-input-wrapper>
  </form>

  <ng-template *ngIf="checkoutType === 'tryForce'; then tryForce_addressForm; else getForce_addressForm"></ng-template>

  <!-- Billing Address form and then Shipping Address form -->
  <ng-template #getForce_addressForm>
    <app-address-form
      class="e2e-billing-address-form"
      [disabled]="!orderDetailsForm.valid"
      #billingAddressForm
    ></app-address-form>

    <label [class.checkbox-hidden]="showSecondAddressForm" class="app-checkbox same-shipping-checkbox">
      <input
        [disabled]="!billingAddressForm.isValid()"
        type="checkbox"
        tabindex="-1"
        [checked]="getUseSameAddress()"
        (change)="onUseSameAddressChange($event)"
      />
      <div class="app-checkbox-icon"></div>
      Billing address is the same as shipping address
    </label>

    <app-address-form
      class="e2e-shipping-address-form"
      *ngIf="!getUseSameAddress()"
      [disabled]="!orderDetailsForm.valid || !billingAddressForm.isValid()"
      [placeholders]="shippingFormPlaceholders"
      #shippingAddressForm
    ></app-address-form>

    <ng-template [ngTemplateOutlet]="creditCardInformation"></ng-template>
  </ng-template>

  <!-- Shipping Address form and then Billing Address form -->
  <ng-template #tryForce_addressForm>
    <app-address-form
      class="e2e-shipping-address-form"
      [disabled]="!orderDetailsForm.valid"
      #shippingAddressForm
    ></app-address-form>

    <label [class.checkbox-hidden]="showSecondAddressForm" class="app-checkbox same-shipping-checkbox">
      <input
        [disabled]="!shippingAddressForm.isValid()"
        type="checkbox"
        tabindex="-1"
        [checked]="getUseSameAddress()"
        (change)="onUseSameAddressChange($event)"
      />
      <div class="app-checkbox-icon"></div>
      Shipping information is same as Billing Address
    </label>

    <app-address-form
      class="e2e-billing-address-form"
      *ngIf="!getUseSameAddress()"
      [disabled]="!orderDetailsForm.valid || !shippingAddressForm.isValid()"
      [placeholders]="billingFormPlaceholders"
      #billingAddressForm
    ></app-address-form>

    <ng-template [ngTemplateOutlet]="billingInformation"></ng-template>
  </ng-template>

  <ng-template #billingInformation>
    <h2 class="e2e-billing-info">Billing Information</h2>
    <span
      class="vwo-why-we-are-asking mb-3"
      fixedHintType="tooltip"
      [position]="'top-middle'"
      [mdUpWidth]="400"
      [mdDownWidth]="400"
      [autoPositioning]="false"
      [hint]="whyWeAreAskingBillingInfoTplVWO"
    >
      <span>Why are we asking for this?</span>
    </span>
  </ng-template>

  <ng-template #creditCardInformation>
    <h2>Credit Card Information</h2>
    <span
      class="vwo-why-we-are-asking"
      fixedHintType="tooltip"
      [position]="'top-middle'"
      [mdUpWidth]="400"
      [mdDownWidth]="400"
      [autoPositioning]="false"
      [hint]="whyWeAreAskingCreditCardTplVWO"
    >
      <span>Why are we asking for this?</span>
      <ng-template #whyWeAreAskingCreditCardTplVWO>
        <div class="tooltip-content">
          <p>
            We require your credit card because your subscription will begin after the free trial period, unless you
            cancel.
          </p>
          <p>
            If you cancel but don't return the GPS devices to us within {{ getTrialDaysCount() }} days from when you
            cancel, we will charge your credit card for the value of the devices ($150 each).
          </p>
        </div>
      </ng-template>
    </span>
  </ng-template>
  <!-- #END Main Content #tplMainContent -->

  <app-recurly-form [formGroup]="cardDetailsForm">
    <div class="credit-card-wrapper">
      <app-recurly-control
        appRecurlyControlValidator="recurly"
        class="credit-card"
        type="card"
        formControlName="cardDetails"
      ></app-recurly-control>
    </div>

    <ng-container *ngTemplateOutlet="tplCreditCardErrors"></ng-container>

    <input type="hidden" data-recurly="first_name" [value]="getBillingAddress()?.firstName || ''" />
    <input type="hidden" data-recurly="last_name" [value]="getBillingAddress()?.lastName || ''" />
    <input type="hidden" data-recurly="address1" [value]="getBillingAddress()?.address1 || ''" />
    <input type="hidden" data-recurly="address2" [value]="getBillingAddress()?.address2 || ''" />
    <input type="hidden" data-recurly="city" [value]="getBillingAddress()?.city || ''" />
    <input type="hidden" data-recurly="country" [value]="getBillingAddress()?.country || 'US'" />
    <input type="hidden" data-recurly="state" [value]="getBillingAddress()?.state || ''" />
    <input type="hidden" data-recurly="postal_code" [value]="getBillingAddress()?.postalCode || ''" />
  </app-recurly-form>

  <div class="vwo-terms-checkbox">
    <div class="md-show">
      <label class="app-checkbox terms-checkbox agree-label">
        By selecting "Start Free Trial", you agree and consent to the Force by Mojio
        <a target="_blank" href="https://forcebymojio.com/terms/">Terms of Service</a>
        and
        <a target="_blank" href="https://forcebymojio.com/privacy/">Privacy Policy</a>
      </label>
    </div>
  </div>

  <app-faq-tooltip *ngIf="checkoutType === 'tryForce'"></app-faq-tooltip>

  <div class="actions md-show">
    <button class="btn-primary" [disabled]="!isValid() || reCaptchaFailed" (click)="onPlaceOrderPressed()">
      Start Free Trial
    </button>
  </div>
  <div class="retry-msg md-show" *ngIf="reCaptchaFailed">Please try again in {{ counter$ | async }} sec</div>

  <order-widget-content>
    <div class="vwo-terms-checkbox">
      <div class="md-hide">
        <label class="app-checkbox terms-checkbox agree-label">
          By selecting "Start Free Trial", you agree and consent to the Force by Mojio
          <a target="_blank" href="https://forcebymojio.com/terms/">Terms of Service</a>
          and
          <a target="_blank" href="https://forcebymojio.com/privacy/">Privacy Policy</a>
        </label>
      </div>
    </div>

    <div class="actions md-hide">
      <button class="btn-primary" [disabled]="!isValid() || reCaptchaFailed" (click)="onPlaceOrderPressed()">
        Start Free Trial
      </button>
    </div>
    <div class="retry-msg md-hide" *ngIf="reCaptchaFailed">Please try again in {{ counter$ | async }} sec</div>

    <div class="errors-right-block">
      <div [formGroup]="orderDetailsForm">
        <div appFormError="email" errorName="required">Please enter email address.</div>
        <div appFormError="email" errorName="emailAsync">
          {{ orderDetailsForm.get('email')?.errors?.emailAsync }}
        </div>
        <div appFormError="email" errorName="email">Please enter a valid email address.</div>

        <div appFormError="phone" errorName="required">Please enter phone number.</div>
        <div appFormError="phone" errorName="mask">Phone number is too short</div>
        <div appFormError="phone" errorName="phoneAsync">
          {{ orderDetailsForm.get('phone')?.errors?.phoneAsync }}
        </div>
      </div>
    </div>
  </order-widget-content>
</app-order-layout>

<ng-template #tplCreditCardErrors>
  <div [formGroup]="cardDetailsForm" appFormError="cardDetails" errorName="recurly" class="cc-error">
    <div *ngIf="cardDetailsForm.controls.cardDetails.errors?.recurly?.remote?.number">
      Credit card number
      {{ cardDetailsForm.controls.cardDetails.errors?.recurly?.remote?.number }}
    </div>
    <div *ngIf="cardDetailsForm.controls.cardDetails.errors?.recurly?.remote?.cvv">
      Credit card cvv
      {{ cardDetailsForm.controls.cardDetails.errors?.recurly?.remote?.cvv }}
    </div>
    <div *ngIf="cardDetailsForm.controls.cardDetails.errors?.recurly?.remote?.expiry">
      Credit card expiry
      {{ cardDetailsForm.controls.cardDetails.errors?.recurly?.remote?.expiry }}
    </div>
    <div *ngIf="cardDetailsForm.controls.cardDetails.errors?.recurly?.remote?.year">
      Year
      {{ cardDetailsForm.controls.cardDetails.errors?.recurly?.remote?.year }}
    </div>
    <div *ngIf="!cardDetailsForm.controls.cardDetails.errors?.recurly?.number?.valid">
      Please provide a valid credit card number
    </div>
    <div *ngIf="!cardDetailsForm.controls.cardDetails.errors?.recurly?.cvv?.valid">Please provide a valid cvv code</div>
    <div *ngIf="!cardDetailsForm.controls.cardDetails.errors?.recurly?.expiry?.valid">
      Please provide a valid expiry date
    </div>
  </div>
</ng-template>
