import { TBillingPeriod } from '../../models/i-order-state.model';
import { TSubscriptionPlan } from '../../types/type';

export function billingPeriodToPlanCode(billingPeriod: TBillingPeriod): TSubscriptionPlan {
  switch (billingPeriod) {
    case 'annual':
      return 'fleet_annual_3';
    case 'monthly':
      return 'fleet_monthly_3';
  }
}
